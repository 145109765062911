import { Col, Row, Typography } from 'antd';
import React from 'react';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
import { Card } from '../../Card/type';
import Title from '../../Card/components/Title';

export const CardHeader = ({ data }: { data: Card | undefined }) => {
  const marginLeft = isMobile ? undefined : 28;
  return (
    <Row gutter={16}>
      <Col lg={18} md={24}>
        <Title data={data} />
        <Typography.Paragraph style={{ marginLeft }}>
          в колонке{' '}
          <Typography.Text underline>{data?.column?.title}</Typography.Text>
        </Typography.Paragraph>
      </Col>
      <Col lg={6} md={24} style={{ textAlign: 'right' }}>
        <Typography.Text type="secondary" style={{ fontSize: '0.85em' }}>
          Дата создания:{' '}
          {data ? dayjs(data.creationTime).format('DD.MM.YYYY HH:mm') : ''}
        </Typography.Text>
      </Col>
    </Row>
  );
};
