import { Card } from '../../Card/type';
import { AccountRole } from '../../Authorization/AuthorizationTypes';
import { Checkbox, Popover, Tag, Typography } from 'antd';
import DateForm from '../../Card/components/DateForm';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useAppSelector } from '../../../store/rootReducer';
import { getDateInfo } from '../../Card/Card';
import { useCardUpdateOnce } from '../../Card/helpers/useCardUpdateOnce';

export const DateSection = ({
  data,
  role,
}: {
  data: Card | undefined;
  role: AccountRole;
}) => {
  const { profile } = useAppSelector((state) => state.authReducer);
  const dateInfo =
    data?.completeDate && getDateInfo(data.completeDate, data.isCompleted);
  const [isOpenDate, setIsOpenDate] = useState(false);
  const cardUpdate = useCardUpdateOnce(data?.id?.toString());

  return (
    <>
      {data?.completeDate && (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
          <Typography.Text strong>Дата </Typography.Text>
          <Checkbox
            defaultChecked={data.isCompleted}
            disabled={
              !data?.accountToTask.find(
                (acc) => acc.account.id === profile?.id ?? 0
              ) && role === 'user'
            }
            onChange={(e) =>
              cardUpdate.mutate({ isCompleted: e.target.checked })
            }
          />
          {role !== 'user' && (
            <Popover
              placement="bottom"
              trigger="click"
              open={isOpenDate}
              onOpenChange={setIsOpenDate}
              content={
                <DateForm card={data!} onCancel={() => setIsOpenDate(false)} />
              }
            >
              <Tag color="blue" style={{ cursor: 'pointer' }}>
                {dayjs(data.completeDate).format('DD.MM.YYYY')}
              </Tag>
            </Popover>
          )}
          {dateInfo && <Tag color={dateInfo.color}>{dateInfo.text}</Tag>}
        </div>
      )}
    </>
  );
};
