import React, { useState } from 'react';
import { Button, Popover, Space, Tag, Typography } from 'antd';
import AddLabels from '../../Card/components/AddLabels';
import { PlusOutlined } from '@ant-design/icons';
import { Card } from '../../Card/type';
import { AccountRole } from '../../Authorization/AuthorizationTypes';

export const LabelsSection = ({
  data,
  role,
}: {
  data: Card | undefined;
  role: AccountRole;
}) => {
  const [isLabelOpen, setIsLabelOpen] = useState(false);

  return (
    <>
      {(data?.labelToTask?.length ?? 0) > 0 && (
        <div>
          <Typography.Paragraph strong>Метки</Typography.Paragraph>
          <Space wrap>
            {data?.labelToTask.map((labelContainer) => (
              <Tag
                key={labelContainer.id}
                color={labelContainer.label.color}
                style={{ margin: 0 }}
              >
                {labelContainer.label.title}
              </Tag>
            ))}
            {role !== 'user' && (
              <Popover
                placement="bottom"
                trigger="click"
                open={isLabelOpen}
                onOpenChange={setIsLabelOpen}
                content={
                  <AddLabels card={data!} setIsLabelOpen={setIsLabelOpen} />
                }
              >
                <Button icon={<PlusOutlined />} />
              </Popover>
            )}
          </Space>
        </div>
      )}
    </>
  );
};
