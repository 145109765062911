import React, { useContext, useEffect } from 'react';
import { Checkbox, Col, Divider, Drawer, Input, Row, Space, Tag } from 'antd';
import { useQuery } from 'react-query';
import { getLabelList, LABEL_URL } from '../../Card/api';
import { useParams } from 'react-router-dom';
import { Label } from '../../Card/type';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FilterContext, FilterList } from '../BoardLayout';
import { useAppSelector } from '../../../store/rootReducer';
import { debounce } from 'lodash';

const FiltersBoard = React.memo(
  ({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: Function }) => {
    const { boardId: id } = useParams();
    const { data: labelList, refetch } = useQuery<Label[]>(
      [LABEL_URL],
      () => getLabelList(+id!),
      { refetchOnWindowFocus: false }
    );

    useEffect(() => {
      refetch();
    }, [id]);

    const { setFilterList, filterList } = useContext(FilterContext);
    const { profile } = useAppSelector((state) => state.authReducer);

    const onChangeCheck = (
      event: CheckboxChangeEvent,
      name: keyof FilterList,
      value: any
    ) => {
      const checked = event.target.checked;
      if (checked) {
        setFilterList!((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        setFilterList!((prevState) => {
          const copy = { ...prevState };
          delete copy[name];
          return copy;
        });
      }
    };

    const onChangeLabel = (event: CheckboxChangeEvent, id: number) => {
      const checked = event.target.checked;
      if (checked) {
        setFilterList!((prevState) => ({
          ...prevState,
          label: [...(prevState.label ?? []), id],
        }));
      } else {
        setFilterList!((prevState) => {
          const copy = { ...prevState };
          if (copy.label?.length === 1) {
            delete copy.label;
          } else {
            return {
              ...prevState,
              label: [
                ...(prevState.label ?? []).filter((labelId) => labelId !== id),
              ],
            };
          }
          return copy;
        });
      }
    };

    const onSearch = (keyWord: string) => {
      if (keyWord) {
        setFilterList!((prevState) => ({ ...prevState, keyWord }));
      } else {
        setFilterList!((prevState) => {
          const copy = { ...prevState };
          delete copy.keyWord;
          return copy;
        });
      }
    };

    const onChangeInput = debounce(onSearch, 300);

    return (
      <Drawer
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={'Фильтры по карточкам'}
      >
        <Input
          placeholder={'Введите ключевое слово'}
          onKeyDown={(e) => e.stopPropagation()}
          allowClear={true}
          onChange={(e) => onChangeInput(e.target.value)}
        />
        <Divider orientation={'left'}>Участники</Divider>
        <Space direction={'vertical'}>
          <Checkbox
            checked={filterList.accountId === profile?.id}
            onChange={(e) => onChangeCheck(e, 'accountId', profile?.id)}
          >
            Назначенные мне
          </Checkbox>
          <Checkbox onChange={(e) => onChangeCheck(e, 'noAccount', true)}>
            Нет участников
          </Checkbox>
        </Space>
        <Divider orientation={'left'}>Даты</Divider>
        <Space direction={'vertical'}>
          <Checkbox onChange={(e) => onChangeCheck(e, 'noDate', true)}>
            <Tag>Без дат</Tag>
          </Checkbox>
          <Checkbox onChange={(e) => onChangeCheck(e, 'overdue', true)}>
            <Tag color={'red'}>Просроченные</Tag>
          </Checkbox>
          <Checkbox onChange={(e) => onChangeCheck(e, 'twoDay', true)}>
            <Tag color={'yellow'}>Срок истекает в течении 2х суток</Tag>
          </Checkbox>
          <Checkbox onChange={(e) => onChangeCheck(e, 'completed', true)}>
            <Tag color={'green'}>Выполненные</Tag>
          </Checkbox>
        </Space>
        <Divider orientation={'left'}>Метки</Divider>
        <Space direction={'vertical'} style={{ width: '100%' }}>
          {labelList?.map((label) => (
            <Row key={label.id}>
              <Col span={2}>
                <Checkbox onChange={(e) => onChangeLabel(e, label.id)} />
              </Col>
              <Col span={22}>
                <Tag style={{ width: '100%' }} color={label.color}>
                  {label.title}
                </Tag>
              </Col>
            </Row>
          ))}
        </Space>
      </Drawer>
    );
  }
);
FiltersBoard.displayName = 'FiltersBoard';
export default FiltersBoard;
