import React, { useState } from 'react';
import { Card } from '../../Card/type';
import { Button, Popover, Space, Typography } from 'antd';
import AvatarCard from '../../Card/components/AvatarCard';
import { PlusOutlined } from '@ant-design/icons';
import AddMemberForm from '../../Card/components/AddMemberForm';
import { AccountRole } from '../../Authorization/AuthorizationTypes';

export const ParticipantsSection = ({
  data,
  role,
}: {
  data: Card | undefined;
  role: AccountRole;
}) => {
  const [isOpenAddMembers, setIsOpenAddMembers] = useState(false);
  return (
    <>
      {(data?.accountToTask?.length ?? 0) > 0 && (
        <div>
          <Typography.Paragraph strong>Участники</Typography.Paragraph>
          <Space>
            {data?.accountToTask.map((account) => (
              <AvatarCard account={account.account} key={account.id} />
            ))}
            {role !== 'user' && (
              <Popover
                placement="bottom"
                trigger="click"
                open={isOpenAddMembers}
                onOpenChange={setIsOpenAddMembers}
                content={
                  <AddMemberForm
                    card={data!}
                    cancelAdd={() => setIsOpenAddMembers(false)}
                  />
                }
              >
                <Button shape="circle" icon={<PlusOutlined />} />
              </Popover>
            )}
          </Space>
        </div>
      )}
    </>
  );
};
