import { useQuery } from 'react-query';
import {
  ACCOUNT_ON_WORKPLACE_ROLE_URL,
  getAccountRole,
} from '../../components/Workspace/Home/components/api';
import { useAppSelector } from '../../store/rootReducer';
import { AccountOnWorkplace } from '../../components/Authorization/AuthorizationTypes';
import { Board as BoardType } from '../../components/Workspace/Board/types';
import {
  BOARD_URL,
  getBoardById,
} from '../../components/Workspace/Board/api/BoardApi';
import { useParams } from 'react-router-dom';

export const useRole = (workplaceId: number) => {
  const { profile } = useAppSelector((state) => state.authReducer);
  const params = { accountId: profile?.id ?? 0, workplaceId };
  const enabled = !!profile?.id && !!workplaceId;
  const accountToWorkplace = useQuery<AccountOnWorkplace>(
    [ACCOUNT_ON_WORKPLACE_ROLE_URL, params],
    () => getAccountRole(params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: enabled,
      placeholderData: { role: 'user' },
    }
  );

  const { data } = accountToWorkplace;

  return data!.role;
};

export const useGetWorkplaceId = () => {
  const { boardId: id } = useParams();
  const boardData = useQuery<BoardType>(
    [BOARD_URL, id!.toString()],
    () => getBoardById(id!),
    { enabled: !!id, refetchOnMount: false, refetchOnWindowFocus: false }
  );

  return boardData?.data?.workplaceId;
};
