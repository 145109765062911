import React, { useEffect, useState } from 'react';
import { Button, Space, Typography } from 'antd';
import { CommentOutlined } from '@ant-design/icons';
import { Card } from '../type';
import { COMMENT_URL } from '../CommentApi';
import { useAppSelector } from '../../../store/rootReducer';
import TextArea from 'antd/es/input/TextArea';
import MdEditorTb from '../../../utils/Copmonents/MdEditorTb';
import { useCreateCommentMutation } from '../helpers/useCreateCommentMutation';
import { isMobile } from 'react-device-detect';

const CreateComment = React.memo(({ card }: { card: Card }) => {
  const [commentEdit, setCommentEdit] = useState<string>();
  const [isComment, setIsComment] = useState(false);
  const { profile } = useAppSelector((state) => state.authReducer);

  const commentMutate = useCreateCommentMutation([
    COMMENT_URL,
    card?.id.toString(),
  ]);

  const sendComment = () => {
    commentMutate
      .mutateAsync({
        taskId: card.id,
        authorId: profile!.id,
        text: commentEdit ?? '',
      })
      .then(() => closeEdit());
  };

  const closeEdit = () => {
    setIsComment(false);
  };

  const openEdit = (e: React.MouseEvent<HTMLTextAreaElement>) => {
    e.stopPropagation();
    setIsComment(true);
  };

  useEffect(() => {
    window.document.addEventListener('mousedown', closeEdit);
    return () => window.document.removeEventListener('mousedown', closeEdit);
  }, []);

  return (
    <Space
      style={{
        width: `calc(100% - ${isMobile ? 0 : 20}px)`,
      }}
      direction={'vertical'}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <Typography.Title level={5}>
        <CommentOutlined style={{ marginRight: 8 }} />
        Комментарии
      </Typography.Title>
      {isComment ? (
        <MdEditorTb value={commentEdit} onChange={setCommentEdit} />
      ) : (
        <TextArea
          style={{ width: '100%' }}
          placeholder={'Напишите комментарий...'}
          onClick={openEdit}
        />
      )}
      {isComment && (
        <Button type={'primary'} onClick={sendComment}>
          Отправить
        </Button>
      )}
    </Space>
  );
});
CreateComment.displayName = 'CreateComment';
export default CreateComment;
