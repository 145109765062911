import React from 'react';
import { Row, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { Card } from '../../Card/type';
import { HomeOutlined } from '@ant-design/icons';

export const ParentTask = ({ data }: { data: Card | undefined }) => {
  const { boardId } = useParams();
  return (
    <div>
      <Row justify="space-between">
        <Typography.Title level={5}>
          <HomeOutlined style={{ marginRight: 8 }} />
          Родительская задача
        </Typography.Title>
      </Row>
      <Link to={`/board/${boardId}/card/${data?.parentTaskId}`}>
        {data?.parentTask?.title ?? ''}
      </Link>
    </div>
  );
};
