import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Modal,
  Result,
  Skeleton,
  theme,
  Typography,
  Watermark,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { Board } from '../Workspace/Board/types';
import { BOARD_URL, BOARD_URL_FULL } from '../Workspace/Board/api/BoardApi';
import { CARD_URL, getCardById } from '../Card/api';
import { Card } from '../Card/type';
import { AxiosError } from 'axios';
import { useHotkeyFunction } from '../Card/helpers/useHotkeyFunction';
import ErrorComponent from '../../utils/Copmonents/ErrorComponent';
import CardViewWrapper from './style/CardViewWrapper';
import { isMobile } from 'react-device-detect';
import StickerElement from '../BoardLayout/components/StickerElement';
import { CardHeader } from './components/CardHeader';
import { CardBody } from './components/CardBody';

const { useToken } = theme;

export const CardView = () => {
  const { id, boardId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [modalView, setModalView] = useState(true);

  const { token } = useToken();

  const placeholderCard = queryClient
    .getQueryData<Board>([BOARD_URL, boardId])
    ?.columnList.flatMap((col) => col.task)
    .find((el) => el.id === +id!);

  const { data, error, isLoading, status } = useQuery<Card, AxiosError>(
    [CARD_URL, 'once', id],
    () => getCardById(id!),
    {
      retry: (_, error) => error?.response?.status !== 403,
      placeholderData: () => placeholderCard,
    }
  );

  const { inArchive, navigateCard } = useHotkeyFunction(data);

  useEffect(() => {
    document.addEventListener('keydown', inArchive);
    document.addEventListener('keydown', navigateCard);
    return () => {
      document.removeEventListener('keydown', inArchive);
      document.removeEventListener('keydown', navigateCard);
    };
  }, [data]);

  const handleCancelModal = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setModalView(false);
  };

  if (status === 'error' && error?.response?.status === 403) {
    navigate('/');
  }

  const isVisibleBackground =
    data &&
    (!!data.stickerToTask.length || data.backgroundUrl || data.backgroundColor);

  const afterClose = () => {
    navigate(
      '..',
      queryClient.getQueryData([CARD_URL, 'once', id])
        ? undefined
        : { replace: true }
    );
    queryClient.removeQueries([BOARD_URL_FULL]);
  };

  return (
    <Modal
      open={modalView}
      footer={false}
      width={800}
      onCancel={handleCancelModal}
      destroyOnClose
      zIndex={40}
      afterClose={afterClose}
      maskClosable={false}
    >
      {status === 'error' ? (
        <Result
          status="500"
          title="500"
          subTitle="Извините, произошла непредвиденная ошибка"
          extra={
            <Button type="primary" onClick={() => setModalView(false)}>
              Назад
            </Button>
          }
        />
      ) : (
        <ErrorComponent>
          <Watermark
            content={data?.archived ? 'В архиве' : undefined}
            font={{ color: 'rgba(0,0,0,.2)' }}
          >
            <Skeleton loading={isLoading} active style={{ marginLeft: 28 }}>
              {isVisibleBackground && (
                <CardViewWrapper
                  $backgroundImage={data.backgroundUrl}
                  $isMobile={isMobile}
                  $backgroundColor={data.backgroundColor}
                  $tokenColorBgLayout={token.colorBgLayout}
                >
                  {data.stickerToTask?.map((sticker) => (
                    <StickerElement
                      key={sticker.id}
                      sticker={sticker}
                      columnId={data.columnId}
                      cardView
                    />
                  ))}
                </CardViewWrapper>
              )}
              {data?.isTemplate && (
                <Alert
                  style={{ marginBottom: 16 }}
                  message={
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      Шаблон карточки
                    </Typography.Title>
                  }
                  type="info"
                />
              )}
              <CardHeader data={data} />
              <CardBody data={data} setModalView={setModalView} />
            </Skeleton>
          </Watermark>
        </ErrorComponent>
      )}
    </Modal>
  );
};

CardView.displayName = 'CardView';
