import { Col, Row, Space } from 'antd';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { LabelsSection } from './LabelSection';
import { DateSection } from './DateSection';
import Menu from '../../Card/components/Menu';
import CommentList from '../../Card/components/CommentList';
import CreateComment from '../../Card/components/CreateComment';
import Subcard from '../../Card/components/Subcard';
import Checklist from '../../Card/components/Checklist';
import { Card } from '../../Card/type';
import Description from '../../Card/components/Description';
import GitlabInfo from '../../Card/components/GitlabInfo';
import { ParticipantsSection } from './ParticipantsSection';
import { ParentTask } from './ParentTask';
import FileList from '../../Card/components/FileList';
import { isMobile } from 'react-device-detect';
import { useGetWorkplaceId, useRole } from '../../../utils/helpers/useRole';

export const CardBody = ({
  data,
  setModalView,
}: {
  data: Card | undefined;
  setModalView: Dispatch<SetStateAction<boolean>>;
}) => {
  const [isAdd, setIsAdd] = useState(false);
  const workplaceId = useGetWorkplaceId();
  const role = useRole(workplaceId ?? 0);

  const gitlabVisible =
    !!data?.gitlabMergeRequestList?.length || !!data?.gitlabCommit?.length;

  return (
    <Row gutter={16}>
      <Col lg={18} style={{ marginTop: 8 }} md={24}>
        <Space
          direction="vertical"
          style={{
            width: 'calc(100% - 20px)',
            marginLeft: isMobile ? 0 : 20,
          }}
          size={20}
        >
          <Space size={'large'}>
            <ParticipantsSection data={data} role={role} />
            <LabelsSection data={data} role={role} />
          </Space>
          <DateSection data={data} role={role} />
          {data?.parentTaskId && <ParentTask data={data} />}
          {!!(isAdd || data?.subTaskList?.length) && (
            <Subcard card={data!} isAdd={isAdd} setIsAdd={setIsAdd} />
          )}
          {data?.checklist && <Checklist card={data!} />}
          <Description description={data?.description} />
          {gitlabVisible && <GitlabInfo card={data!} />}
          {!!data?.fileList?.length && <FileList data={data} />}
          <CreateComment card={data!} />
          <CommentList />
        </Space>
      </Col>
      <Col lg={6} md={24}>
        {role !== 'user' && (
          <Menu
            card={data!}
            setIsAddCard={setIsAdd}
            setModalView={setModalView}
          />
        )}
      </Col>
    </Row>
  );
};
